.ticket-month-date {}

.ticket-month {
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  top: 10px;
  /* left:48px; */
  width: 100%;
  margin: auto;
}

.ticket-date {
  font-weight: 700;
  font-size: 64.69px;
  text-align: center;
  top: 21px;
  color: #ffffff;
  /* left:48px; */
  /* background-color: red; */
  width: 100%;
  margin: auto;
}

.ticket-year {
  font-weight: 600;
  font-size: 18.4px;
  line-height: 34px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  margin: auto;

  bottom: 7px;
}

.event-date-wrapper.sm img {
  width: 78.54px;
  height: 58.83px;
}

.event-date-wrapper.sm .ticket-month {
  font-weight: 600;
  font-size: 9.48826px;
  top: 5px;
}

.event-date-wrapper.sm .ticket-date {
  top: 20px;
  font-weight: 700;
  font-size: 23.8116px;
  line-height: 17px;
}

.event-date-wrapper.sm .ticket-year {
  font-weight: 600;
  font-size: 9.48826px;
  bottom: -7px;
}

.event-date-wrapper.md img {
  width: 115px;
  height: 105px;
}

.event-date-wrapper.md .ticket-month {
  font-weight: 600;
  font-size: 13.1863px;
  top: 10px;
}

.event-date-wrapper.md .ticket-date {
  font-weight: 700;
  font-size: 46.152px;
  top: 23.5px;
}

.event-date-wrapper.md .ticket-year {
  font-weight: 600;
  font-size: 13.1863px;
  bottom: 1px;
}

.switch-component {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 31px;
}

.switch-component input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  border: #ffffff 1px solid;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #fff;
  border: none !important;
}

input:focus+.slider {}

input:checked+.slider:before {
  -webkit-transform: translateX(28.5px);
  -ms-transform: translateX(28.5px);
  transform: translate(31px, -1px);
  background: #00e194;
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

@keyframes beat {
  to {
    transform: scale(1.2);
  }

}

.loader-logo {
  animation: beat 1.2s infinite alternate;
}


