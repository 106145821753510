.about-us-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #ffffff;
}
.mobileview{
  display: none;
}
.mobileview .about-img_containner{
  display: none;
}
.page-title {
  padding-top: 20px;
  padding-bottom: 20px;
  background: #00e194;
  color: #ffffff;
  font-weight: 300;
  font-size: 45px;
  line-height: 75px;
  /* identical to box height */
}

.about_us-header {
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 30px;
}/* 
.desAbou{
  background-image: 
  linear-gradient(90deg, #000000 1.38%, rgba(0, 0, 0, 0) 10%),
  linear-gradient(270deg, #000000 1.38%, rgba(0, 0, 0, 0) 10%),
  linear-gradient(315deg, #000000 5.38%, rgba(0, 0, 0, 0) 18%),
  linear-gradient(292deg, #000000 5.38%, rgba(0, 0, 0, 0) 18%),
  linear-gradient(337.5deg, #000000 1.38%, rgba(0, 0, 0, 0) 18%),
  linear-gradient(225deg, #000000 5.38%, rgba(0, 0, 0, 0) 18%),
  linear-gradient(247.5deg, #000000 1.38%, rgba(0, 0, 0, 0) 18%),
  linear-gradient(202.5deg, #000000 1.38%, rgba(0, 0, 0, 0) 18%),
  linear-gradient(157.5deg, #000000 1.38%, rgba(0, 0, 0, 0) 18%),
  linear-gradient(112.5deg, #000000 1.38%, rgba(0, 0, 0, 0) 18%),
  linear-gradient(67.5deg, #000000 1.38%, rgba(0, 0, 0, 0) 18%),
  linear-gradient(22.5deg, #000000 1.38%, rgba(0, 0, 0, 0) 18%),
  linear-gradient(135deg, #000000 5.38%, rgba(0, 0, 0, 0) 18%),
  linear-gradient(45deg, #000000 5.38%, rgba(0, 0, 0, 0) 18%),
   linear-gradient(360deg, #000000 1.38%, rgba(0, 0, 0, 0) 8%),
    linear-gradient(180deg, #000000 1.38%, rgba(0, 0, 0, 0) 8%),
    url("../Assets//img/about_us.svg");
    position: absolute;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center; 
} */
.about_us-text {
  color: #FFF;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.about-us__sub-header {
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 50px !important;
  line-height: normal;
  /* background: url('../Assets/img/header.png');
  background-repeat: no-repeat;
  height: 5em;
  background-size: cover;
  background-position:  center ; */
}

.feature_header {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  color: #FFF;
  margin: 29px 0 12px 0;
}
.mobil, .spacingImg {
  margin-top: 0 !important;
}

.feature-text {
  color: #FFF;
  /* text-align: center; */
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  line-height: 20px;
}

.feature-textt {
  color: #FFF;
  /* text-align: center; */
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  line-height: 20px;
}

.about-img_containner {
  width: 100%;
  height: 100%;
}
.about_img_container{
  background-repeat: no-repeat !important
  ;
}

.page-section-title.about-section-title {
  font-size: 50px;
  line-height: 70px;

}

.about-section__wrapper {
  margin-bottom: 130px;
}

.mar{
  margin: 0;
  margin-top: auto;
  
}
.about_img_container {
 
  background-repeat: no-repeat !important;
  background-size: cover !important; 
  background-position-x: center !important;
  background-position-y: center !important;
  height: 480px !important;
  
  border-radius: 350px;
  box-shadow: 0px 0px 20px 10px #000 inset;
}
.checkk{
  height: fit-content;
}
.about_img_container-mobile{
  display: none;
}
/* 

@media (max-width: 769px) {
  .page-title {
    padding-top: 39px;
    padding-bottom: 40px;
    font-size: 28px;
    line-height: 0 !important;
    text-align: center;
    font-weight: 400;
  }
} */
@media (max-width:999px) and (min-width:280px) {
  .abtt{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .mm{
    top: 25px !important;
    height: 150px !important;
  }
  
  .spacingImg{
    display: none;
  }/* 
  .mobileAbou{
    background-image: 
    linear-gradient(90deg, #000000 1.38%, rgba(0, 0, 0, 0) 10%),
  linear-gradient(270deg, #000000 1.38%, rgba(0, 0, 0, 0) 10%),
  linear-gradient(315deg, #000000 5.38%, rgba(0, 0, 0, 0) 18%),
  linear-gradient(292deg, #000000 5.38%, rgba(0, 0, 0, 0) 18%),
  linear-gradient(337.5deg, #000000 1.38%, rgba(0, 0, 0, 0) 18%),
  linear-gradient(225deg, #000000 5.38%, rgba(0, 0, 0, 0) 18%),
  linear-gradient(247.5deg, #000000 1.38%, rgba(0, 0, 0, 0) 18%),
  linear-gradient(202.5deg, #000000 1.38%, rgba(0, 0, 0, 0) 18%),
  linear-gradient(157.5deg, #000000 1.38%, rgba(0, 0, 0, 0) 18%),
  linear-gradient(112.5deg, #000000 1.38%, rgba(0, 0, 0, 0) 18%),
  linear-gradient(67.5deg, #000000 1.38%, rgba(0, 0, 0, 0) 18%),
  linear-gradient(22.5deg, #000000 1.38%, rgba(0, 0, 0, 0) 18%),
  linear-gradient(135deg, #000000 5.38%, rgba(0, 0, 0, 0) 18%),
  linear-gradient(45deg, #000000 5.38%, rgba(0, 0, 0, 0) 18%),
   linear-gradient(360deg, #000000 1.38%, rgba(0, 0, 0, 0) 8%),
    linear-gradient(180deg, #000000 1.38%, rgba(0, 0, 0, 0) 8%),
    url("../Assets//img/about_us.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
  } */
}
@media (max-width:999px) and (min-width:500px) {
  .about_img_container{
    display: none;
  }
  .mobil{
    width: 100%;
    padding-left: 60px;
    margin-top: -40px;
    padding-right: 60px;
  }
 
  
 .mobileview.about-img_containner{
  display: none;
 }
 .about_img_container-mobile{
  width: 100%;
  display: flex;
  height: 583px;
  background-size: contain !important;
 }
 .about_us-header{
  text-align: center;
  font-size: 30px;
 } 
 .about_us-text{
  text-align: center ;
  font-size: 17px;
 }
 .mobileview{
  display: contents;
  margin-top: 0;
 }/* 
 .mobileview img{
  margin-top: 50px;
 } */
 .webview{
  display: none;
 }
 .about-us__sub-header {
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.mobil{
  
  margin-top: 30px;
}
.feature_header{
  text-align: center;
  font-size: 20px;
}
.feature-text{
  text-align: center;
}
.page-section-title.about-section-title{
  font-size: 29px;
}
}

@media (max-width:600px) and (min-width:501px) {
  .about_img_container{
    display: none;
  }
 .about_img_container-mobile{
  width: 100%;
  display: flex;
  margin-top: -40px;
  height: 663px;
  background-size: cover !important;
 }

 .mobileview{
  display: flex;
 }
 .mobileview.about-img_containner{
  display: flex;
 }
 .mobileview img{
  margin-top: 50px;
 }
 .webview{
  display: none !important;
 }
 .webview .about-img_containner{
  display: none;
 }
}
@media (max-width:500px) and (min-width:280px) {
  .page-section-title.dif::before{
    bottom: 10px;
  }
  .about_img_container{
    display: none;
  }
 .about_img_container-mobile{
  width: 100%;
  display: flex;
  background-position-x: center !important;
  background-position-y: center !important;
  margin-left: 0px ;
  border-radius: 200px;
  margin-right: 0px;
  box-shadow: 0px 0px 20px 10px #000 inset;
  padding-left: 0px;
  padding-right: 0px;
  height: 423px;
  background-size: cover !important;
 }

 .about_us-header{
  text-align: center;
 } 
 .about_us-text{
  text-align: center ;
 }
 .mobileview{
  display: flex;
  margin-top: 0;
  height: 400px;
 }
 .mobileview .about-img_containner{
  display: flex;
 }
 .mobileview img{
 }
 .webview{
  display: none !important;
 }
 .about-us__sub-header {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.feature_header{
  text-align: center;
}
.mobil{
  
  margin-top: 0px;
}
.feature-text{
  text-align: center;
  width: 100%;
}
.page-section-title.about-section-title{
  font-size: 29px;
}
}
@media (max-width:300px) and (min-width:280px) {
 
  
 .about_img_container-mobile{
  
  height: 323px;
 }
  .about_us-header{
   font-size: 20px;
  } 
  .about_us-text{
   font-size: 13px;
  }
  .about-us__sub-header {
   font-size: 20px;
 }
 .feature_header{
  font-size: 17px;
 }
 .feature-text{
  font-size: 13px;
 }
 .page-section-title.about-section-title{
   font-size: 29px;
 }
 }