body {
    background: #000 !important;
    background-color: #000 !important;
    font-family: "ClashDisplay-Variable", sans-serif !important;
    /* font-family: "", sans-serif !important; */
  }
.upcomingevent{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.title{
    width: 100%;
    height: 131px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.tit{
    width: fit-content;
    height: 131px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.navlink{
    display: "flex";
 justify-Content: "space-between";
 align-Items: "center";
  height: "fit-content";
  font-size: 20px;
   width: "fit-content";
    color: #00E194
}
.navlink img{
    width: 52px;
    margin-left: 10px;
    height: 50px;
}
#difff{
    font-size: 70px ;
}
.upcoming-event-section{
    margin-top: 40px;
}
@media (min-width: 280px) and (max-width: 1000px) {
   .upcoming-event-section{
    margin-top: 10px;
   }
   
.upcomingevent{
    width: calc(100% - 30px);
}
.title{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    align-items: end;
    justify-content: space-between;
}
.tit{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: start;
}
#difff{
    font-size: 20px ;
}
.navlink{
    display: "flex";
 justify-Content: "space-between";
 align-Items: "center";
 margin-top: 30px;
  height: "fit-content";
  font-size: 10px;
   width: "fit-content";
    color: #00E194
}
.navlink img{
    width: 26px;
    margin-left: 10px;
    height: 25px;
}  #difff::before{
    width: 22%;
}
}

@media (min-width: 800px) and (max-width: 1000px) {
   
    #difff{
        font-size: 70px ;
    }
    #difff::before{
        bottom: 30px;
    }
    
    .navlink{
        font-size: 23px;
    }
    }

    @media (min-width: 600px) and (max-width: 799px) {
   
        #difff{
            font-size: 60px ;
        }
        .navlink{
            font-size: 20px;
        }
        }
    @media (min-width: 500px) and (max-width: 549px) {
   
        #difff{
            font-size: 50px ;
        }
        
        .navlink{
            font-size: 15px;
        }
        }
        @media (min-width: 400px) and (max-width: 449px) {
   
            #difff{
                font-size: 40px ;
            }
            .navlink{
                font-size: 15px;
            }
            }
            @media (min-width: 300px) and (max-width: 349px) {
   
                #difff{
                    font-size: 30px ;
                }
                }
                @media (min-width: 350px) and (max-width: 399px) {
   
                    #difff{
                        font-size: 35px ;
                    }
                    .navlink{
                        font-size: 13px;
                    }
                    }
                    @media (min-width: 450px) and (max-width: 499px) {
   
                        #difff{
                            font-size: 45px ;
                        }
                        .navlink{
                            font-size: 16px;
                        }
                        }
                        @media (min-width: 550px) and (max-width: 599px) {
   
                            #difff{
                                font-size: 55px ;
                            }
                            .navlink{
                                font-size: 19px;
                            }
                            }